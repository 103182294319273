@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f6;
  background-color: #f6f6f6;
}

code {
  font-family: "Rubik", sans-serif;
}

table,
div,
input,
p,
button,
span,
input::placeholder {
  font-family: "Rubik", sans-serif;
}

html {
  font-size: 90%;
}

@media (min-width: 640px) {
  html {
    font-size: 60%;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 80%;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 90%;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 100%;
  }
}
