.arrow-button {
  display: flex;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
}

.arrow-button:hover {
  width: 100%;
  border-radius: 24px;
  justify-content: left;
}

.arrow-button .absolute {
  display: flex;
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: bold;
  align-items: center;
  font-size: 12px;
  left: 36%;
}

.arrow-button > .arrow {
  width: 7px;
  height: 7px;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 6px;
  transition: all 0.3s ease;
}

.arrow-button > .arrow::before {
  display: block;
  background-color: #ffffff;
  width: 20px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 1;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all 0.3s ease;
  content: "";
  right: 0;
}

.arrow-button:hover > .arrow {
  transform: rotate(-45deg) translate(7px, 7px);
  border-color: text-hover-color;
}

.arrow-button:hover > .arrow::before {
  opacity: 1;
  width: 14px;
}

.arrow-button:hover {
  background-color: #00CDAE;
  color: #fff;
  border: none;
}

.arrow-button-black {
  display: flex;
  color: #000000;
  background-color: transparent;
  border: 1px solid #000000;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
}

.arrow-button-black:hover {
  width: 100%;
  border-radius: 24px;
  justify-content: left;
}

.arrow-button-black .absolute {
  display: flex;
  color: #000000;
  transition: all 0.3s ease;
  font-weight: bold;
  align-items: center;
  font-size: 12px;
  left: 36%;
}

.arrow-button-black:hover .absolute {
  color: #ffffff;
}

.arrow-button-black > .arrow {
  width: 7px;
  height: 7px;
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 6px;
  transition: all 0.3s ease;
}

.arrow-button-black > .arrow::before {
  display: block;
  background-color: #000000;
  width: 20px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 1;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all 0.3s ease;
  content: "";
  right: 0;
}

.arrow-button-black:hover > .arrow {
  transform: rotate(-45deg) translate(7px, 7px);
  border-color: #ffffff;
  margin-left: 2rem;
}

.arrow-button-black:hover > .arrow::before {
  opacity: 1;
  width: 14px;
  background-color: #ffffff;
}

.arrow-button-black:hover {
  background-color: #00CDAE;
  color: #fff;
  border: none;
}

@media (min-width: 1024px) {
  .arrow-button {
    display: flex;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
  }
  
  .arrow-button:hover {
    width: 100%;
    border-radius: 32px;
    justify-content: left;
  }
  
  .arrow-button .absolute {
    display: flex;
    color: #ffffff;
    transition: all 0.3s ease;
    font-weight: bold;
    align-items: center;
    font-size: 16px;
    left: 38%;
  }
  
  .arrow-button > .arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all 0.3s ease;
  }
  
  .arrow-button > .arrow::before {
    display: block;
    background-color: #ffffff;
    width: 30px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 1;
    bottom: calc(-2px / 2);
    transform: rotate(45deg);
    transition: all 0.3s ease;
    content: "";
    right: 0;
  }
  
  .arrow-button:hover > .arrow {
    transform: rotate(-45deg) translate(10px, 10px);
    border-color: text-hover-color;
  }
  
  .arrow-button:hover > .arrow::before {
    opacity: 1;
    width: 20px;
  }
  
  .arrow-button:hover {
    background-color: #00CDAE;
    color: #fff;
    border: none;
  }
  
  
  
  .arrow-button-black {
    display: flex;
    color: #000000;
    background-color: transparent;
    border: 1px solid #000000;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
  }
  
  .arrow-button-black:hover {
    width: 100%;
    border-radius: 32px;
    justify-content: left;
  }
  
  .arrow-button-black .absolute {
    display: flex;
    color: #000000;
    transition: all 0.3s ease;
    font-weight: bold;
    align-items: center;
    font-size: 16px;
    left: 38%;
  }
  
  .arrow-button-black:hover .absolute {
    color: #ffffff;
  }
  
  .arrow-button-black > .arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all 0.3s ease;
  }
  
  .arrow-button-black > .arrow::before {
    display: block;
    background-color: #000000;
    width: 30px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 1;
    bottom: calc(-2px / 2);
    transform: rotate(45deg);
    transition: all 0.3s ease;
    content: "";
    right: 0;
  }
  
  .arrow-button-black:hover > .arrow {
    transform: rotate(-45deg) translate(10px, 10px);
    border-color: #ffffff;
    margin-left: 2rem;
  }
  
  .arrow-button-black:hover > .arrow::before {
    opacity: 1;
    width: 20px;
    background-color: #ffffff;
  }
  
  .arrow-button-black:hover {
    background-color: #00CDAE;
    color: #fff;
    border: none;
  }
}
