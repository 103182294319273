.footer-links {
  @apply leading-9;
  @apply text-secondary-500;
  @apply duration-300;
  @apply font-light;
}

.footer-links:hover {
  @apply text-primary-500;
}

.footer-icon {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply w-8;
  @apply h-8;
  @apply duration-300;
}
