.header-ads {
  background-image: url("../../assets/imgs/ne-header-ads.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 24px;
}

.base-ads {
  background-image: url("../../assets/imgs/ne-base-bg.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.base-ads-mobile {
  background-image: url("../../assets/imgs/ne-base-mobile-bg.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.prof-bg {
  background-image: url("../../assets/imgs/ne-prof-bg.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.prof-mobile-bg {
  background-image: url("../../assets/imgs/ne-prof-bg.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.lh-5 {
  line-height: 1.8;
}
