.document-ads {
  background-image: url("../../assets/imgs/ne-about-document.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 24px;
}

.about-gradient {
  background-image: url("../../assets/imgs/ne-about-gradient.png");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}

.about-gradient-mobile {
  background-image: url("../../assets/icons/ne-about-mobile-gradient.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}

.what-we-do-bg {
  background-image: url("../../assets/imgs/ne-what-we-do.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 42px;
}

.mission-bg {
  background-image: url("../../assets/icons/ne-mission-bg.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 42px;
}

.vision-bg {
  background-image: url("../../assets/icons/ne-vision-bg.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 42px;
}

.happy-clerk-ads {
  background-image: url("../../assets/imgs/ne-happy-clerk.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}