* {
  @apply text-secondary-500;
}

p {
  @apply text-secondary-500;
}

.container {
  width: 100%;
  @apply mx-auto;
  @apply px-5;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.big-shadow {
  box-shadow: 0px 3.46736px 13.0026px rgba(44, 56, 145, 0.38);
}

.ant-form-item {
  @apply m-0;
}

.home-header {
  background-image: url("./assets/imgs/ne-main-bg.png");
  // background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}