.delivery-ads {
  background-image: url("../../assets/imgs/ne-delivery.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 24px;
}

.services-center-bg {
  background-image: url("../../assets/icons/ne-services-center-bg.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}

.services-lady-bg {
  background-image: url("../../assets/imgs/ne-services-lady.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
