.style_lg_active {
  color: #00CDAE !important;
  font-weight: 500 !important;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
  margin: 0px 1rem;
}

.style_lg_incative {
  @apply text-secondary-500;
  margin: 0px 1rem!important;
  @apply font-light;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
}

.style_lg_incative:hover {
  @apply text-primary-500;
  @apply font-medium;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
}

.style_sm_active {
  @apply text-primary-500;
  @apply text-body-2xl;
  @apply block;
  @apply px-3;
  @apply py-2;
  @apply rounded-md;
  @apply font-medium;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
}

.style_sm_incative {
  @apply text-white;
  @apply text-body-2xl;
  @apply block;
  @apply px-3;
  @apply py-2;
  @apply font-light;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
}

.style_sm_incative:hover {
  @apply text-primary-500;
  @apply font-medium;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
}

.nav-bar-floated {
  box-shadow: 0px 1px 10px #999;
}

.style_lg_incative {
  display: inline-block;
  position: relative;
}

.style_lg_incative::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #111C44;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.style_lg_incative:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.style_lg_active {
  display: inline-block;
  position: relative;
}

.style_lg_active::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #111C44;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  transform: scaleX(1);
  transform-origin: bottom left;
}

.style_sm_incative:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.style_sm_active {
  display: inline-block;
  position: relative;
}

.style_sm_active::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e2da85;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  transform: scaleX(1);
  transform-origin: bottom left;
}

.mobile-gradient {
  background-image: url("../../assets/icons/ne-mobile-gradient.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}